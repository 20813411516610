export type ConfigProperties = {
  Sidebar_drawer: unknown
  Customizer_drawer: boolean
  mini_sidebar: boolean
}

const config: ConfigProperties = {
  Sidebar_drawer: null,
  Customizer_drawer: false,
  mini_sidebar: false
}

export default config
