<script setup lang="ts">
import { useThemeStore } from '@/stores/theme'
const customizer = useThemeStore()
const menuItems = useRoleBasedMenuItems()
</script>

<template>
  <v-navigation-drawer
    v-model="customizer.Sidebar_drawer"
    left
    elevation="0"
    rail-width="75"
    app
    class="leftSidebar"
    :rail="customizer.mini_sidebar"
    width="270"
  >
    <div class="pa-5">
      <v-img width="200" src="/von-rundstedt-logo.png" />
    </div>

    <perfect-scrollbar class="scrollnavbar">
      <v-list class="pa-6" density="compact">
        <template v-for="item in menuItems" :key="item.title">
          <NavGroup v-if="item.header" :item="item" />
          <NavCollapse v-else-if="item.children" class="leftPadding" :item="item" :level="0" />
          <NavItem v-else :item="item" class="leftPadding" />
        </template>
      </v-list>
      <!-- <div class="pa-6 userbottom">
        <LcFullVerticalSidebarProfile />
      </div> -->
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>
