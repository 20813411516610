<script setup lang="ts">
import { lightTheme, outlinedIcons, type NotivueTheme } from 'notivue'
import { useThemeStore } from '@/stores/theme'

const customizer = useThemeStore()

const notivueTheme: NotivueTheme = {
  ...lightTheme,
  '--nv-global-border': '#313237',
  '--nv-radius': '0.4rem',
  '--nv-tip-width': '3px',
  '--nv-icon-size': '1.15rem',
  '--nv-spacing': '1rem',
  // '--nv-y-align-has-title': 'flex-start',
  '--nv-shadow':
    'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px'
}
</script>

<template>
  <div>
    <Notivue v-slot="item">
      <NotivueSwipe :item="item">
        <Notification :item="item" :theme="notivueTheme" :icons="outlinedIcons" />
        <NotificationProgress :item="item" />
      </NotivueSwipe>
    </Notivue>
    <v-app
      theme="light"
      class="light horizontalLayout"
      :class="[customizer.mini_sidebar ? 'mini-sidebar' : '']"
    >
      <VerticalSidebar />
      <HorizontalHeader />
      <v-main>
        <v-container fluid class="page-wrapper pb-sm-15 pb-10">
          <div class="maxWidth">
            <NuxtPage />
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<!-- <template>
  <div>
    <Notivue v-slot="item">
      <NotivueSwipe :item="item">
        <Notification :item="item" :theme="notivueTheme" :icons="outlinedIcons" />
        <NotificationProgress :item="item" />
      </NotivueSwipe>
    </Notivue>
    <v-app>
      <v-navigation-drawer v-model="drawer" width="330">
        <v-row class="pa-5" align="center">
          <v-col cols="3">
            <v-avatar color="primary" size="52">
              <span class="text-h5">{{ user.username.substring(0, 2).toUpperCase() }}</span>
            </v-avatar>
          </v-col>
          <v-col cols="6">
            <p class="mt-2 font-weight-black">
              {{ user.username }}
            </p>
            <p class="text-grey">{{ user.group }}</p>
          </v-col>
          <v-col cols="3">
            <v-btn variant="text" color="red" class="rounded-0 ml-3" @click="logoutUser">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-list>
          <v-list-item
            v-for="(child, k) in menu"
            :key="k"
            :prepend-icon="child.icon"
            :value="child.text"
            exact
            style="height: 50px"
            link
            :to="child.route"
          >
            <v-list-item-title>{{ child.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar>
        <v-app-bar-nav-icon @click="drawer = !drawer" />

        <v-toolbar-title>
          <v-img width="200" src="/von-rundstedt-logo.png" />
        </v-toolbar-title>
      </v-app-bar>

      <v-main>
        <v-container>
          <v-row justify="center">
            <v-col cols="12" md="9">
              <slot></slot>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<style scoped lang="scss">
.v-list-item--active {
  border-left: 5px solid rgb(98, 0, 238);
  background-color: white;
}
</style> -->
