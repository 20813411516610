<script setup lang="ts">
const authStore = useAuthStore()

const { user, logout } = authStore

const logoutUser = async () => {
  await logout()
  navigateTo('/')
}
</script>

<template>
  <v-menu :close-on-content-click="false">
    <template #activator="{ props }">
      <v-btn class="custom-hover-primary" variant="text" v-bind="props" icon>
        <v-avatar color="primary" size="35">
          {{ user.username.substring(0, 2).toUpperCase() }}
        </v-avatar>
      </v-btn>
    </template>
    <v-sheet rounded="md" width="360" elevation="10">
      <div class="px-8 pt-6">
        <div class="d-flex align-center mt-4 pb-6">
          <v-avatar size="70" color="primary">
            <span class="font-h1">{{ user.username.substring(0, 2).toUpperCase() }}</span>
          </v-avatar>
          <div class="ml-3">
            <h6 class="text-h6 mb-n1">{{ user.username }}</h6>
            <span class="text-subtitle-1 font-weight-regular textSecondary">{{ user.group }}</span>
          </div>
        </div>
        <v-divider />
      </div>
      <div class="pt-4 pb-6 px-8 text-center">
        <v-btn color="primary" variant="outlined" block @click="logoutUser">Logout</v-btn>
      </div>
    </v-sheet>
  </v-menu>
</template>
