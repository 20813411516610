import { IconLayoutDashboard, IconBriefcase2Filled, IconFeatherFilled } from '@tabler/icons-vue'

interface menu {
  header?: string
  title?: string
  icon?: unknown
  to?: string
  chip?: string
  chipBgColor?: string
  chipColor?: string
  chipVariant?: string
  chipIcon?: string
  children?: menu[]
  disabled?: boolean
  type?: string
  subCaption?: string
}

export const useRoleBasedMenuItems = () => {
  const { user } = useAuthStore()
  const group = user?.group

  const menuItems: menu[] = [
    { header: 'Home' },
    {
      title: 'Dashboard',
      icon: IconLayoutDashboard,
      to: '/admin/dashboard'
    },
    {
      title: 'Partner',
      icon: IconFeatherFilled,
      to: '/admin/partner'
    },
    {
      title: 'Mandanten',
      icon: IconBriefcase2Filled,
      to: '/admin/mandanten'
    }
    /*  {
      text: 'Fragebögen',
      route: '/admin/fragebogen',
    }, */
  ]

  if (group === 'Admin') {
    return menuItems
  }
  if (group === 'Partner') {
    const items = menuItems.filter(item => item.title !== 'Partner')
    return items
  }
  return []
}
