<script setup lang="ts">
const properties = defineProps({
  item: {
    type: Object,
    required: true
  },
  level: {
    type: Number,
    default: 0
  }
})
</script>

<template>
  <v-list-group no-action>
    <template #activator="{ props }">
      <v-list-item
        v-bind="props"
        :value="properties.item.title"
        rounded
        class="mb-1"
        color="primary"
      >
        <template #prepend>
          <Icon :item="properties.item.icon" :level="level" />
        </template>
        <v-list-item-title class="mr-auto">
          {{ item.title }}
        </v-list-item-title>
        <v-list-item-subtitle
          v-if="properties.item.subCaption"
          class="text-caption mt-n1 hide-menu"
        >
          {{ properties.item.subCaption }}
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <template v-if="properties.item.children">
      <template v-for="(subitem, i) in properties.item.children" :key="i">
        <NavCollapse v-if="subitem.children" :item="subitem" :level="level + 1" />
        <NavItem v-else :item="subitem" :level="level + 1" />
      </template>
    </template>
  </v-list-group>
</template>
