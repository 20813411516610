<script setup lang="ts">
const properties = defineProps({
  item: {
    type: Object,
    required: true
  },
  level: {
    type: Number,
    default: 0
  }
})
</script>

<template>
  <v-list-item
    :to="item.type === 'external' ? '' : item.to"
    :href="item.type === 'external' ? item.to : ''"
    rounded
    class="mb-1"
    :disabled="item.disabled"
    :target="item.type === 'external' ? '_blank' : ''"
  >
    <template #prepend>
      <Icon :item="properties.item.icon" :level="level" />
    </template>
    <v-list-item-title>{{ properties.item.title }}</v-list-item-title>
    <v-list-item-subtitle v-if="properties.item.subCaption" class="text-caption mt-n1 hide-menu">
      {{ properties.item.subCaption }}
    </v-list-item-subtitle>
    <template v-if="properties.item.chip" #append>
      <v-chip
        :color="properties.item.chipColor"
        :class="'sidebarchip hide-menu bg-' + properties.item.chipBgColor"
        :size="properties.item.chipIcon ? 'small' : 'small'"
        :variant="properties.item.chipVariant"
        :prepend-icon="properties.item.chipIcon"
      >
        {{ properties.item.chip }}
      </v-chip>
    </template>
  </v-list-item>
</template>
