<script setup>
const properties = defineProps({
  item: {
    type: Function,
    required: true
  },
  level: {
    type: Number,
    default: 0
  }
})
</script>

<template>
  <template v-if="properties.level > 0">
    <component :is="properties.item" size="14" stroke-width="1.5" class="iconClass" />
  </template>
  <template v-else>
    <component :is="properties.item" size="20" stroke-width="1.5" class="iconClass" />
  </template>
</template>
